import { Form } from '@formio/react';
import { Navigate } from 'react-router-dom';

function Connect({connected}){

    return(
        <>
        {
            !connected ?
            <Form src="https://form.ido-data.fr/dev/testlogin" 
                                options={{
                                  language: 'fr',
                                  i18n: {
                                    fr: {
                                      cancel: 'annuler',
                                      previous: 'précédent',
                                      next: 'suivant',
                                      submit: 'envoyer',
                                      complete: 'envoi réussi'
                                    }}}} 
                                onSubmit={() => window.location.href = '/'}/> 
            : <Navigate to='/'/>
        }
        </>
    )

}

export default Connect
import serviceSeriousGame from '../img/Service-serious-game.png';
import  seriousGamesChallenges from '../img/serious-games-components-challenges_1.png';
import { Link } from 'react-router-dom';

function Home(){

    return(
        <main>
            <section className='personnalise'>
                <div>
                    <h1>Créez votre Serious Game personnalisé</h1>
                    <p>Transformez votre communication en une expérience ludique et immersive </p>
                    <Link to='/contactez-nous'>Demander une demo</Link>
                </div>
                <img src={serviceSeriousGame} alt="services serious games builder" />
            </section>

            <section className='challenges'>
                <div>
                    <h2>Un Serious Game (jeu sérieux) est un outil de formation, communication et simulation, sous forme de jeu.</h2>
                    <p>Serious Games Builder vous permet de présenter votre contenu de communication de manière attractive grâce à la gamification.  </p>
                </div>
                <img src={seriousGamesChallenges} alt="composant serious games" />
            </section>

        </main>

    )
}

export default Home;
import { Form } from '@formio/react';
import { Navigate } from 'react-router-dom';

function Question({connected}){
    return(
        <>
            {
                connected ?
                <Form src="https://form.ido-data.fr/dev/question" 
                                options={{
                                  language: 'fr',
                                  i18n: {
                                    fr: {
                                      cancel: 'annuler',
                                      previous: 'précédent',
                                      next: 'suivant',
                                      submit: 'envoyer',
                                      complete: 'envoi réussi'
                                    }}}} />
                : <Navigate to='/se-connecter'/>
            }
        </>
    )
}

export default Question;
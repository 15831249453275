import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { Form } from '@formio/react';
import Header from './components/Header';
import Home from './components/Main';
import Connect from './components/Connect';
import Univers from './components/Univers';
import Jeu from './components/Jeu';
import Question from './components/Question';
import Contact from './components/Contact';
import './App.css';


function App() {

  const [connected, setConnected] = useState(localStorage.getItem('formioToken') !== null ? true : false);
  const [token, setToken] = useState(localStorage.getItem('formioToken'));

  // listen to localStorage for changes
  useEffect(() => {
    window.addEventListener("storage", () => {
      setToken(localStorage.getItem('formioToken'));
    });

  }, []);

  // launch isConnected if token changes
  useEffect(()=> {
    isConnected();
  }, [token]);


  //check if token null or not to know if user is connected
  const isConnected = () => {
    if(token !== null){
      setConnected(true);
    }
    else{
      setConnected(false);
    }
    window.dispatchEvent(new Event("storage"));
  }

  //disconnect user
  const disconnect = () => {
    localStorage.removeItem('formioToken');
    localStorage.removeItem('formioUser');
    window.dispatchEvent(new Event("storage"));
  }

  
  return (
    <>
      <Router>
        <Header connected={connected} disconnect={disconnect}/>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/se-connecter' element={<Connect connected={connected}/>} />
          <Route path='/creer-un-univers'  element={<Univers connected={connected}/>} />
          <Route path='/creer-un-jeu' element={<Jeu connected={connected}/>}/>
          <Route path='/creer-une-question' element={<Question connected={connected}/>}/>
          <Route path='/contactez-nous' element={<Contact />} />
        </Routes>
      </Router>
    </>
  );
}
export default App;

import { Form } from '@formio/react';
import { Navigate } from 'react-router-dom';

function Contact(){
    return(
        <>
            <Form src="https://form.ido-data.fr/dev/mdccontactyt" 
                            options={{
                                language: 'fr',
                                i18n: {
                                fr: {
                                    cancel: 'annuler',
                                    previous: 'précédent',
                                    next: 'suivant',
                                    submit: 'envoyer',
                                    complete: 'envoi réussi'
                                }}}} />
        </>
    )
}

export default Contact;
import logo from '../img/Serious-Games-BuilderV2.png';
import { Link } from 'react-router-dom';

function Header({connected, disconnect}){

    return(
        <header>
            <Link to='/'><img src={logo} alt="logo"/></Link>
            <nav>
                {
                    !connected &&
                    <Link to='/se-connecter'>Se connecter</Link>
                }
                {
                    connected &&
                    <>
                        <Link to='/creer-un-univers'>Créer un univers</Link>
                        <Link to='/creer-un-jeu'>Créer un jeu</Link>
                        <Link to='/creer-une-question'>Créer une question</Link>
                    </>
                }
                
                
                <Link to='/contactez-nous'>Contactez nous</Link>
                {
                    connected &&
                    <Link to='/' onClick={()=> disconnect()}><i className="fas fa-power-off" ></i></Link>
                    
                }
            </nav>
        </header>
    )
}

export default Header